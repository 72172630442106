import {useCallback, useEffect, useState} from 'react';

export type Sizes = Readonly<{
  boundingHeight: number;
  boundingWidth: number;
}>;

export type Result = Readonly<{
  ref: (element: HTMLElement | null) => void;
  sizes: Sizes | null;
}>;

export default function useSizeRef(): Result {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [sizes, setSizes] = useState<Sizes | null>(null);

  const handleResize = useCallback(() => {
    if (element != null) {
      const rect = element.getBoundingClientRect();
      setSizes({
        boundingHeight: rect.height,
        boundingWidth: rect.width,
      });
    }
  }, [element]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    ref: setElement,
    sizes,
  };
}
