import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';
import {IconProps} from 'react-feather';

export default function createFeatherIcon(
  Component: React.ComponentType<IconProps>,
): React.ComponentType<AppIconProps> {
  function Icon(props: AppIconProps): JSX.Element {
    const iconProps = useFeatherIconProps(props);
    return <Component {...iconProps} />;
  };
  return Icon;
}