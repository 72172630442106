export type Coordinates = Readonly<{
  x: number,
  y: number,
}>;

export default function getTouchLocation(e: TouchEvent): Coordinates | null {
  const touch = e.touches.item(0);
  if (touch != null) {
    return {
      x: touch.clientX,
      y: touch.clientY,
    };
  }
  return null;
}